import { Box, Typography } from "@mui/material";

const ExpectedScore = ({ t, score, timestamp }) => {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        gap: "1rem",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.5rem 1rem",
        backgroundColor: "#FFFFFF",
        display: "flex",
      }}
    >
      <form
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <Box element={"figure"}>
          <img
            alt="Proficiency Meter"
            src="/proficient-meter.svg"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Typography
          sx={{
            borderRadius: "4px",
            color: "#3D7C65",
            fontWeight: "bold",
          }}
        >
          {t("ExpectedScoreTitle")}:
        </Typography>
        <Box
          variant="h6"
          sx={{
            borderRadius: "6px",
            padding: "8px",
            backgroundColor: "#3D7C65",
            color: "white",
          }}
        >
          <Typography fontWeight={"bold"} padding={"0 0.25rem"}>
            <Typography
              element="span"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "inline",
                },
              }}
            >
              {t("PL")}
            </Typography>{" "}
            {score}
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default ExpectedScore;
